import React, { useState } from 'react';
import {
  Box,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { PluginCategory, TPluginConstructInfo, TPluginInfo } from '../../../../generated/gql/graphql';
import { useEditorStore } from '../../../hooks/EditorState';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ScreenshotMonitorRoundedIcon from '@mui/icons-material/ScreenshotMonitorRounded';
import DataObjectRoundedIcon from '@mui/icons-material/DataObjectRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import OutputRoundedIcon from '@mui/icons-material/OutputRounded';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import InputRoundedIcon from '@mui/icons-material/InputRounded';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import { SvgIconComponent } from '@mui/icons-material';
import { useShallow } from 'zustand/react/shallow';


export function PluginIcon(props: {
  category: PluginCategory,
  disabled?: boolean,  // only disable when not on hover
}): React.ReactElement {
  const [hover, setHover] = useState(false);
  const Icon = getPluginIcon(props.category);
  return <Tooltip title={props.category}>
    <Box
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      display='flex' justifyContent='center'
    >
      <Icon
        fontSize='inherit'
        color={props.disabled && !hover ? 'disabled' : getPluginIconColor(props.category)}
      />
    </Box>
  </Tooltip>;
}


function getPluginIconColor(category: PluginCategory) {
  switch (category) {
    case PluginCategory.AiPowered: return 'warning';
    case PluginCategory.Display: return 'secondary';
    case PluginCategory.UserInput: return 'primary';
    default: return undefined;
  }
}


function getPluginIcon(category: PluginCategory): SvgIconComponent {
  switch (category) {
    case PluginCategory.Computation:
      return DataObjectRoundedIcon;
    // ret.push(<PluginIcon description='Computation'>
    //   <DataObjectRoundedIcon fontSize='inherit' />
    // </PluginIcon>);
    // break;
    case PluginCategory.Control:
      return AccountTreeOutlinedIcon
    case PluginCategory.DataDestination:
      return OutputRoundedIcon
    case PluginCategory.DataSource:
      return InputRoundedIcon
    case PluginCategory.Retrieval:
      return ArticleOutlinedIcon
    case PluginCategory.Settings:
      return SettingsOutlinedIcon
    case PluginCategory.AiPowered:
      return AutoAwesomeOutlinedIcon
    case PluginCategory.Display:
      return ScreenshotMonitorRoundedIcon
    case PluginCategory.UserInput:
      return MoreHorizRoundedIcon
    case PluginCategory.Internet:
      return LanguageRoundedIcon
    case PluginCategory.ChromeExtensionOnly:
      return ExtensionOutlinedIcon
    default:
      return HelpOutlineOutlinedIcon
  }
}



export function PluginSecondaryCategories(props: {
  info?: TPluginInfo | TPluginConstructInfo,
}): React.ReactElement {

  const secondaryCategories = props.info?.categories?.slice(1);

  return secondaryCategories?.length
    ? <Stack direction='row' display='flex' spacing={1} flexWrap='wrap'>
      {secondaryCategories.map((c, idx) => <PluginIcon key={idx} category={c} />)}
    </Stack>
    : <></>
}


export function MainPluginIcon(props: {
  info?: TPluginInfo | TPluginConstructInfo,
}): React.ReactElement {
  const primaryCategory = props.info?.categories?.[0];
  return primaryCategory
    ? <PluginIcon category={primaryCategory} />
    : <QuestionMarkRoundedIcon fontSize='inherit' />
}


export function FlowNodeName(props: {
  nodeId: string,
  variant: 'title' | 'description',
}): React.ReactElement {
  const nodeDisplayName = useEditorStore(state => state.graph.nodes.find(n => n.id === props.nodeId)?.data?.displayName);
  const nodeType = useEditorStore(useShallow(state => state.graph.nodes.find(n => n.id === props.nodeId)?.data?.pluginType));
  const typeInfo = useEditorStore(state => state.actions.graph.getTypeInfo()[props.nodeId]);

  const displayName = nodeDisplayName
    || typeInfo?.pluginInfo?.name
    || nodeType?.static
    || nodeType?.dynamic?.constructType;

  const icon = nodeType
    ? <MainPluginIcon info={typeInfo?.pluginInfo || typeInfo?.constructInfo} />
    : <ErrorOutlineIcon />;

  if (props.variant == 'title') {
    return <Stack direction='row' spacing={1} display='flex' alignItems='center'>
      <Typography
        variant='h4'
        color={nodeType ? undefined : 'error'}
        component='span'
      >
        {icon}
      </Typography>
      <Stack display='flex' spacing={1} justifyContent='center' alignItems='center' flexGrow={1}>
        <Typography
          variant='h5'
          color={nodeType ? undefined : 'error'}
          flexGrow={1}
          textAlign='center'
        >
          <b>{props.nodeId}{displayName && '.'} </b>{displayName}
        </Typography>
        <PluginSecondaryCategories info={typeInfo?.pluginInfo || typeInfo?.constructInfo} />
      </Stack>
    </Stack>;
  }

  return <Stack direction='row' spacing={1} display='flex' alignItems='center'>
    <Typography
      variant='h5'
      color={nodeType ? undefined : 'error'}
    >
      {icon}
    </Typography>
    <Typography
      variant='subtitle1'
      color={nodeType ? undefined : 'error'}
      flexGrow={1}
      textAlign='center'
    >
      <b>{props.nodeId}{displayName && '.'} </b>{displayName}
    </Typography>
  </Stack>;
}
