import React, { useEffect, useState } from 'react';
import { Box, Button, Card, MobileStepper, Popover, Stack, Typography, useTheme } from '@mui/material';
import { InlineCode } from './common';
import { JSONSchema7 } from 'json-schema';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { isObject } from '../../utils/isObject';

function ListValueDisplay(props: {
  list: React.ReactNode[];
  indent?: number;
}): React.ReactElement {
  const maxSteps = props.list.length;
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(maxSteps - 1);

  useEffect(() => setActiveStep(maxSteps - 1), [maxSteps]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return <Card sx={{ p: 1, ml: props.indent }} elevation={2}>
    {props.list.length > 1
      ? <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={<Button
          size="small"
          onClick={handleNext}
          disabled={activeStep === maxSteps - 1}
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </Button>}
        backButton={<Button size="small" onClick={handleBack} disabled={activeStep === 0}>
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </Button>} />
      : undefined}
    {props.list[activeStep]}
  </Card>;
}


export function ValueDisplay(props: {
  value: any;
  schema?: JSONSchema7;
  indent?: number;
  hideUndefined?: boolean;
}): React.ReactElement {
  const valueText = props.value === undefined
    ? <InlineCode>undefined</InlineCode>
    : props.value === null
      ? <InlineCode>null</InlineCode>
      : props.value.toString();
  const childIndent = (props.indent || 0) + 1;

  const [popoverContent, setPopoverContent] = useState<React.ReactNode | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const popoverOpen = Boolean(anchorEl) && Boolean(popoverContent);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, content: React.ReactNode) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(content);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent(null);
  };

  if (props.value === undefined) {
    return <Typography variant='subtitle2'>Not Set</Typography>;
  }

  const renderField = (
    field: string,
    val: any,
    hideUndefined?: boolean
  ) => <Stack spacing={1} key={field}>
      <Box pl={props.indent}>
        <Typography variant='subtitle2'>{field}</Typography>
      </Box>
      <ValueDisplay
        value={val}
        indent={childIndent}
        hideUndefined={hideUndefined} />
    </Stack>;

  return <Stack spacing={1} width='100%' height='100%'>
    {isObject(props.value)
      ? Object.entries(props.value).map(([field, val]) => renderField(field, val, false))
      : Array.isArray(props.value)
        ? <ListValueDisplay list={props.value.map((v, idx) => <ValueDisplay key={idx} value={v} />)} indent={childIndent} />
        : props.hideUndefined && props.value === undefined
          ? undefined
          : <Box pl={props.indent}>
            <Typography
              variant='body2'
              // onClick={e => handlePopoverOpen(e, <Typography whiteSpace='pre-wrap'>{valueText}</Typography>)}
              sx={{ whiteSpace: 'pre-wrap' }}
            >
              {valueText}
            </Typography>
          </Box>
    }

    <Popover
      open={popoverOpen}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handlePopoverClose}
      sx={{
        maxHeight: '50vh',
      }}
    >
      <Box p={2} overflow='auto'>
        {popoverContent}
      </Box>
    </Popover>
  </Stack>;
}
