import { useMutation } from "@apollo/client";
import { useTheme, keyframes, Divider } from "@mui/material";
import { alpha, Box, GlobalStyles, Stack, IconButton, Typography, Button, Collapse, TextField } from "@mui/material";
import React, { useMemo, useState, useEffect, useContext, useCallback } from "react";
import ReactFlow, { ReactFlowProvider, Edge, useReactFlow, useNodesInitialized, Panel, Controls } from "reactflow";
import { useShallow } from "zustand/react/shallow";
import { TAppDebugInfo, DataLabel } from "../../../../../generated/gql/graphql";
import { ValueDisplay } from "../../../../components/pixie/ValueDisplay";
import { JSONInputField } from "../../../../components/pixie/common";
import { AppContext } from "../../../../contexts/AppContext";
import { SAVE_LABELED_RESULT } from "../../../../graphql/mutation";
import { useEditorStore } from "../../../../hooks/EditorState";
import { flowNodeType, GraphNode } from "../../../../types/GraphNode";
import FlowNode from "../../Editor/FlowNode";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';
import { useClientStore } from "../../../../hooks/ClientState";
import { VerticalCarousel } from "../../../../components/carousel-v2";
import { FlowNodeName } from "../../Editor/FlowNodeName";
import { InlineCode } from "../../../../components/material-markdown";
import JsonView from "react18-json-view";
import { Icon } from "@iconify/react";
import 'reactflow/dist/style.css';
import { AppRun } from "../../../../hooks/useAppRun";
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';


type VisitedMap = { [key: string]: TAppDebugInfo };
type VisitedMapWithOrdering = { map: VisitedMap, order: string[] };


function getFromVisitedMap(map: VisitedMap, pluginId: string, version: number): TAppDebugInfo | undefined {
  const key = JSON.stringify([pluginId, version]);
  return map[key];
}


function createVisitedMapWithOrdering(logs: TAppDebugInfo[]): VisitedMapWithOrdering {
  return logs.reduce((mapWithOrder, log) => {
    const key = JSON.stringify([log.pluginId, log.nodeVersion]);
    if (log.pluginId === log.nodeId && !mapWithOrder.map[key]) {
      mapWithOrder.order.push(key);
    }
    mapWithOrder.map[key] = log;
    return mapWithOrder;
  }, { map: {}, order: [] } as VisitedMapWithOrdering);
}


export function DebugView(props: {
  appRun: AppRun,
  onClose: () => void,
  width: string,
}): React.ReactElement {
  const [
    nodes,
    edges,
  ] = useEditorStore(
    useShallow(state => [
      state.graph.nodes,
      state.graph.edges,
    ])
  );

  const logs = props.appRun.debugLogs;
  const { map: visitedMap, order: visitedOrder } = useMemo(() => createVisitedMapWithOrdering(logs), [logs]);
  const [selectedLog, setSelectedLog] = useState<TAppDebugInfo | undefined>(undefined);
  const theme = useTheme();
  const pulseAnimation = keyframes`
  0% {
    box-shadow: 5px 5px 20px ${alpha(theme.palette.primary.main, 0.5)}
  }
  50% {
    box-shadow: 5px 5px 30px ${alpha(theme.palette.secondary.main, 0.7)}
  }
  100% {
    box-shadow: 5px 5px 20px ${alpha(theme.palette.primary.main, 0.5)}
  }
  `;

  const styledNodes = useMemo(() => {
    return nodes.map(n => {
      const copiedNode = structuredClone(n);
      copiedNode.selected = false;
      if (!getFromVisitedMap(visitedMap, n.id, 0)) return copiedNode;
      if (n.id === logs[logs.length - 1]?.nodeId) {
        copiedNode.className = 'glow-box';
      }
      else {
        copiedNode.style = {
          ...n.style,
          borderRadius: 16,
          backgroundColor: alpha(theme.palette.success.light, 0.2),
        };
      }
      if (n.id === selectedLog?.nodeId) {
        copiedNode.selected = true;
      }
      return copiedNode;
    })
  }, [nodes, visitedMap, logs[logs.length - 1]?.nodeId, selectedLog?.nodeId, theme.palette.success.light]);

  // update selected to the last log whenever logs change
  useEffect(() => {
    setSelectedLog(logs[logs.length - 1]);
  }, [logs.length]);

  return <Box width={props.width} height='100%' overflow='hidden'>
    <GlobalStyles styles={{
      '.glow-box': {
        animation: `${pulseAnimation} 2s ${theme.transitions.easing.easeInOut} infinite`,
      }
    }} />
    <ReactFlowProvider>
      <DebugGraphView
        appRun={props.appRun}
        nodes={styledNodes}
        edges={edges}
        visitedMap={visitedMap}
        visitedOrder={visitedOrder}
        selected={selectedLog}
        onSelect={setSelectedLog}
        menu={<Stack spacing={1} p={1}>
          <IconButton color='error' onClick={props.onClose}><CloseRoundedIcon /></IconButton>
        </Stack>}
      />
    </ReactFlowProvider>
  </Box>
}

const nodeTypes = { [flowNodeType]: FlowNode };
const proOptions = { hideAttribution: true }


function VisitedNodeCarouselItem(props: {
  log: TAppDebugInfo,
  // -1 if not visited in flow
  visitedOrder: number,
  onSelect: () => void,
  selected?: boolean,
}): React.ReactElement {
  const theme = useTheme();
  const inFlow = props.visitedOrder >= 0;

  return <Box
    onClick={props.onSelect}
    sx={{
      cursor: 'pointer',
      backgroundColor: props.selected
        ? alpha(theme.palette.primary.main, 0.2)
        : 'transparent',
      borderRadius: 4,
      padding: 1,
      margin: 1,
    }}
  >
    {/* Because Carousel would set the item's display to inline-block we have to have a nested stack inside box for the flex display of children */}
    <Stack direction='row' spacing={1} alignItems='center' pl={1} pr={1}>
      {inFlow
        ? <Typography variant="subtitle1"><b>{props.visitedOrder + 1}. </b></Typography>
        : <Box pl={2}><Icon icon='mdi:arrow-up-left' /></Box>
      }
      <FlowNodeName nodeId={props.log.pluginId} variant='description' />
      <Box flexGrow={1} />
      {inFlow && <InlineCode>v{props.log.nodeVersion + 1}</InlineCode>}
    </Stack>
  </Box>
}


export default function DebugGraphView(props: {
  nodes: GraphNode[];
  edges: Edge[];
  menu: React.ReactNode;
  selected: TAppDebugInfo | undefined;
  onSelect: (log: TAppDebugInfo) => void;
  visitedMap: VisitedMap;
  visitedOrder: string[];
  appRun: AppRun,
}): React.ReactElement {
  // fit view on load
  const theme = useTheme();
  const { fitView } = useReactFlow();
  const initialized = useNodesInitialized();
  useEffect(() => {
    if (initialized) {
      const timeoutId = setTimeout(
        () => {
          window.requestAnimationFrame(() => fitView());
        },
        100
      );
      return () => clearTimeout(timeoutId);
    };
  }, [initialized]);

  return <Box width='100%' height='100%' sx={{
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    borderRadius: 4,
    border: `1px solid ${theme.palette.text.disabled}`,
  }}>
    <ReactFlow
      nodeTypes={nodeTypes}
      nodes={props.nodes}
      edges={props.edges}
      multiSelectionKeyCode={null}
      nodesDraggable={false}
      nodesConnectable={false}
      elementsSelectable={false}
      proOptions={proOptions}
      fitView
    >
      {!isEmpty(props.visitedMap) && <Panel position='top-left' style={{ maxWidth: '30%' }}>
        <Box sx={{
          background: theme.palette.background.paper,
          borderRadius: 2,
          border: `2px solid ${theme.palette.text.disabled}`,
          boxShadow: `5px 5px 5px rgba(0, 0, 0, 0.5)`,
          width: '100%',
          p: 1,
        }}>
          <VerticalCarousel
            maxHeight='60vh'
            items={Object.entries(props.visitedMap)}
            renderItem={([key, log], idx) => <VisitedNodeCarouselItem
              key={idx}
              log={log}
              visitedOrder={props.visitedOrder.indexOf(key)}
              selected={props.selected === log}
              onSelect={() => props.onSelect(log)}
            />}
            scrollToEndOnItemsChange
          />
        </Box>
      </Panel>}
      <Panel position='bottom-left'>
        {props.menu}
      </Panel>
      <Panel position="top-right" style={{ maxWidth: '40%' }}>
        <DebugLogView selectedLog={props.selected} appRun={props.appRun} />
      </Panel>
      <Controls showInteractive={false} position="bottom-right" />

    </ReactFlow>
  </Box>;
}

function isEmpty(obj: any) {
  return obj === undefined || obj === null || Object.keys(obj).length === 0;
}

function DebugLogView(props: {
  selectedLog: TAppDebugInfo | null,
  appRun: AppRun,
}): React.ReactElement {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(true);
  const nextNodeId = useEditorStore(state => {
    if (!props.selectedLog?.pluginNext) return null;
    return state.graph.edges.find(e =>
      e.source === props.selectedLog?.nodeId
      && e.sourceHandle === props.selectedLog.pluginNext
    )?.target || null;
  });
  const updateDebugLogs = useClientStore(state => state.updateDebugLogs);
  const clearMessages = useClientStore(state => state.clearMessages);

  const removeDebugLogsAfter = useCallback((log: TAppDebugInfo) => {
    updateDebugLogs(logs => {
      const idx = logs.findIndex(l => l.nodeId === log.nodeId && l.nodeVersion === log.nodeVersion);
      if (idx < 0) return logs;
      // we are assuming the entire step (node) will be rerun
      return logs.slice(0, idx);
    });
  }, []);

  if (!props.selectedLog) {
    return <></>
  }

  const nodeNameWithVersionElem = <Stack direction='row' display='flex' alignItems='center' justifyContent='space-between' spacing={1}>
    <FlowNodeName nodeId={props.selectedLog.nodeId} variant='description' />
    <InlineCode>v{props.selectedLog.nodeVersion + 1}</InlineCode>
  </Stack>

  return <Stack sx={{
    background: theme.palette.background.paper,
    borderRadius: 2,
    border: `2px solid ${theme.palette.text.disabled}`,
    boxShadow: `5px 5px 5px rgba(0, 0, 0, 0.5)`,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: '100%',
    '& > *': { width: '100%' },
    p: 2,
  }}>
    <Stack direction='row' spacing={1} display='flex' justifyContent='center'>
      <Button size='small' onClick={() => setIsOpen(s => !s)} sx={{ textTransform: 'none' }}>
        {isOpen ? 'hide details' : 'show details'}
      </Button>
    </Stack>

    <Collapse in={isOpen} sx={{ whiteSpace: 'pre-wrap', overflow: 'auto' }}>
      <Divider />
      <Stack pt={2} spacing={2} divider={<Divider variant="middle" />}>
        {props.selectedLog.pluginId === props.selectedLog.nodeId
          ? nodeNameWithVersionElem
          : <Stack spacing={2}>
            <FlowNodeName nodeId={props.selectedLog.pluginId} variant='description' />
            <Stack direction="row" spacing={1} alignItems='center' justifyContent='center'>
              <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>in</Typography>
              {nodeNameWithVersionElem}
            </Stack>
          </Stack>
        }
        <Button
          variant='outlined'
          onClick={() => {
            props.appRun.disconnect(true);
            removeDebugLogsAfter(props.selectedLog);
            clearMessages();
            props.appRun.send(
              [],
              null,
              {
                nodeId: props.selectedLog.nodeId,
                version: props.selectedLog.nodeVersion,
              },
              true,
            );
          }}
        ><ReplayRoundedIcon /></Button>
        {nextNodeId && <Stack spacing={2}>
          <Typography variant="subtitle2">Proceed to</Typography>
          <FlowNodeName nodeId={nextNodeId} variant='description' />
        </Stack>}
        {!isEmpty(props.selectedLog.pluginParams) && <Stack spacing={2}>
          <Typography variant="subtitle2">Parameters</Typography>
          <JsonView src={props.selectedLog.pluginParams} />
        </Stack>}
        {!isEmpty(props.selectedLog.pluginResultData) && <Stack spacing={2}>
          <Typography variant="subtitle2">Result</Typography>
          <JsonView src={props.selectedLog.pluginResultData} />
        </Stack>}

        {/* {flowId
          ? <ResultLabelingView log={props.selectedLog} />
          : undefined} */}
      </Stack>
    </Collapse>
  </Stack>;
}

function ResultLabelingView(props: {
  log: TAppDebugInfo;
}): React.ReactElement {
  const [label, setLabel] = useState<DataLabel | null>(null);
  const [editedLog, setEditedLog] = useState<TAppDebugInfo>(props.log);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);
  const [save] = useMutation(SAVE_LABELED_RESULT);
  const { setError, setSuccessMessage } = useContext(AppContext);

  const [
    flowId,
    clientId,
  ] = useClientStore(useShallow(state => [
    state.flowId,
    state.clientId,
  ]))


  useEffect(() => {
    setEditedLog(props.log);
    setLabel(null);
    setInProgress(false);
    setSaved(false);
  }, [props.log]);

  useEffect(() => {
    setEditedLog(props.log);
  }, [label]);

  const canLabel = flowId && props.log.pluginId && clientId;

  const saveLabeledResult = useCallback(() => {
    if (!canLabel) return;
    let resultOverride = null as { next: string | null | undefined; data: any; } | null;
    if (label == DataLabel.Bad) {
      resultOverride = {
        next: editedLog.pluginNext,
        data: editedLog.pluginResultData,
      };
    }
    setInProgress(true);
    save({
      variables: {
        flowId,
        pluginId: props.log.pluginId!,
        clientId,
        nodeId: props.log.nodeId,
        version: props.log.nodeVersion,
        label: DataLabel.Good, // always set to good since when labeled bad, we'd be storing the overriden result
        resultOverride,
      }
    }).then(res => {
      if (res.errors) setError(res.errors[0]);
      if (res.data?.asaveLabeledResult) {
        setSaved(true);
        setSuccessMessage(`Labeled result saved with id ${res.data.asaveLabeledResult}`);
      }
      else setError("Something went wrong. Labeled result not saved");
    })
      .catch(setError)
      .finally(
        () => setInProgress(false)
      );
  }, [props, label, editedLog]);

  return <>
    {canLabel
      ? <Stack direction='row' spacing={2}>
        <Box>
          <IconButton
            color={label == DataLabel.Good ? 'success' : 'default'}
            onClick={() => setLabel(DataLabel.Good)}
            disabled={inProgress || saved}
          ><ThumbUpRoundedIcon /></IconButton>
          <IconButton
            color={label == DataLabel.Bad ? 'error' : 'default'}
            onClick={() => setLabel(DataLabel.Bad)}
            disabled={inProgress || saved}
          ><ThumbDownRoundedIcon /></IconButton>
        </Box>
        <Button
          variant='outlined' sx={{ textTransform: 'none' }}
          disabled={!label || inProgress || saved}
          onClick={saveLabeledResult}
          color={saved ? 'success' : 'primary'}
        >
          {saved ? 'Saved' : 'Save'}
        </Button>
      </Stack>
      : undefined}
    {canLabel && label == DataLabel.Bad
      ? <>
        <TextField
          name='next' label='Next'
          value={editedLog.pluginNext}
          onChange={e => setEditedLog(l => ({ ...l, pluginNext: e.target.value || null }))} />
        <JSONInputField
          label='Result data'
          value={editedLog.pluginResultData}
          onChange={v => setEditedLog(l => ({ ...l, pluginResultData: v }))} />
      </>
      : <ValueDisplay value={props.log.pluginResultData} />}
  </>;
}
