import React, { useContext, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Stack, Dialog, Button } from '@mui/material';
import { AssetsContext } from '../../contexts/AssetsContext';
import { DatasetEditor } from '../pixie/DatasetEditor';


export default function DatasetSelect(props: {
  datasetId?: string,
  onChange: (datasetId?: string) => void,
  label?: string,
  siteId?: string,
  appId?: string,
}): React.ReactElement {
  const { datasets } = useContext(AssetsContext);
  const [dialogOpen, setDialogOpen] = useState(false);

  return <Stack spacing={1} width='100%' p={2}>
    <FormControl>
      {props.label && <InputLabel id={props.label}>{props.label}</InputLabel>}
      <Select
        label={props.label}
        labelId={props.label}
        value={props.datasetId || ''}
        onChange={e => {
          if (e.target.value) {
            props.onChange(e.target.value);
          }
          else {
            setDialogOpen(true);
          }
        }}
      >
        <MenuItem value=''>
          <Button variant='contained' sx={{ textTransform: 'none' }} onClick={() => {
            props.onChange(undefined);
            setDialogOpen(true);
          }}>Upload new dataset</Button>
        </MenuItem>
        {datasets.map(d => <MenuItem key={d.datasetId} value={d.datasetId}>{d.datasetName}</MenuItem>)}
      </Select >
    </FormControl>
    <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth='lg'>
      <DatasetEditor
        datasetIdWithName={datasets.find(d => d.datasetId === props.datasetId)}
        siteId={props.siteId}
        appId={props.appId}
        onSubmit={datasetId => {
          props.onChange(datasetId);
          setDialogOpen(false);
        }}
      />
    </Dialog>
  </Stack>
}
