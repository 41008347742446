import React, { useEffect, useState } from 'react';
import { AppContainer } from '../../../../components/pixie/AppContainer';
import { ExternalGraphQLClientProvider } from './ExternalGraphQLClientProvider';
import { StylingWithDefaults } from '../../../../types/ClientState';
import { TFont, TLayout, TPalette } from '../../../../../generated/gql/graphql';
import { defaultLayout, useClientStore } from '../../../../hooks/ClientState';
import { AppClientInPopover } from '../Web/AppClient';
import { useShallow } from 'zustand/react/shallow';
import { Theme } from '@mui/material';
import { useApolloClient } from '@apollo/client';
import { graphql } from '../../../../../generated/gql/gql';
import { useEditorStore } from '../../../../hooks/EditorState';


export interface EmbeddedChatAppProps {
  authToken?: string;
  useLocalhost?: boolean;
  palette?: TPalette;
  font?: TFont;
  layout?: TLayout;
  onStylingLoaded?: (styling: StylingWithDefaults) => void;
  theme?: Partial<Theme>;
}

export function EmbeddedChatApp(props: EmbeddedChatAppProps): React.ReactElement {
  const httpUrl = props.useLocalhost ? 'http://localhost:8000/graphql' : 'https://gopixie.ai/graphql';
  const wsUrl = props.useLocalhost ? 'ws://localhost:8000/graphql' : 'wss://gopixie.ai/graphql';
  const flowId = useClientStore(state => state.flowId);
  const debug = useEditorStore(state => state.graph.nodes.length > 0);

  return <ExternalGraphQLClientProvider httpUrl={httpUrl} wsUrl={wsUrl} authToken={props.authToken}>
    <AppContainer>
      <PluginTypesLoader shouldLoad={debug}>
        <AppClientInPopover
          flowId={flowId}
          {...props}
          initialShowDebug={debug}
          layout={debug
            ? {
              ...defaultLayout,
              dialogWidth: '100vw',
              dialogHeight: '100vh',
            }
            : props.layout
          }
        />
      </PluginTypesLoader>
    </AppContainer>
  </ExternalGraphQLClientProvider>;
}

// load plugin types from the server when appState exists
function PluginTypesLoader(props: {
  shouldLoad?: boolean,
  children: React.ReactNode,
}) {
  const apolloClient = useApolloClient();
  const loadStaticTypes = useEditorStore(state => state.graphql.loadStaticTypes);
  // use this to load dynamic types
  const loadDynamicTypes = useEditorStore(state => state.graphql.loadDynamicTypes);
  const [staticTypesLoaded, setStaticTypesLoaded] = useState(false);
  const [dynamicTypesLoaded, setDynamicTypesLoaded] = useState(false);

  useEffect(() => {
    if (props.shouldLoad) {
      loadStaticTypes(apolloClient).finally(() => setStaticTypesLoaded(true));
      // important to pass null as appId here to not update appState
      // the goal is to load required dynamic types for constructed plugins in the current appState nodes
      loadDynamicTypes(apolloClient).finally(() => setDynamicTypesLoaded(true));
    }
  }, [props.shouldLoad, apolloClient]);

  // TODO visual indication that loading is in progress
  return (!props.shouldLoad || (staticTypesLoaded && dynamicTypesLoaded)) && <>{props.children}</>;
}


// TODO option to use localStorage instead of sessionStorage
export function EmbeddedChatAppWithMemory(props: EmbeddedChatAppProps): React.ReactElement {

  const flowId = useClientStore(state => state.flowId);
  const setClientId = useClientStore(state => state.setClientId);

  useEffect(() => {
    const cid = sessionStorage.getItem(flowId);
    if (cid) {
      setClientId(cid);
    }
  }, [flowId]);

  useEffect(() => {
    const unsub = useClientStore.subscribe(
      state => [state.clientId, state.flowId],
      ([clientId, flowId]) => {
        if (flowId && clientId) {
          sessionStorage.setItem(flowId, clientId);
        }
      },
    );
    return unsub;
  }, []);

  return <EmbeddedChatApp {...props} />;
}
