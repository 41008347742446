import React, { createContext, useEffect, useState } from 'react'
import { Box, Button, Card, IconButton, Popover, Stack, TextField, TextFieldVariants, Typography, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { OnConnectStartParams } from 'reactflow';
import { GraphNode } from '../../types/GraphNode';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { EditorMode } from '../../types/AppState';

export type ChangableProps<T> = {
  data: T,
  onChange: (newData: T) => void,
  onDelete?: () => void,
}

export type EditViewProps<T> = {
  data: T,
  getSaveButton: (newData: T) => React.ReactElement,
  cancelButton: React.ReactElement,
}

export function PopoverSelect<T>(props: {
  anchorEl: HTMLElement | null,
  options: T[],
  onSelect: (selected?: T) => void,
  onClose: () => void,
  getLabel: (item: T) => string,
  selected?: T,
  title?: React.ReactNode,
}): React.ReactElement {

  return <Popover
    open={props.anchorEl !== null}
    anchorEl={props.anchorEl}
    onClose={props.onClose}
  >
    <Box display='flex' width='100%' justifyContent='flex-end'>
      <IconButton onClick={props.onClose}><CloseIcon fontSize='small' /></IconButton>
    </Box>
    {props.options.length == 0
      ? <Typography p={3} pt={0}>nothing available</Typography>
      : <Stack p={3} pt={0} display='flex' alignItems='center'>
        {props.title}
        {props.options.map((item, idx) => <Button
          key={idx}
          variant={item == props.selected ? 'contained' : 'text'}
          sx={{ textTransform: 'none' }}
          onClick={() => {
            if (item == props.selected) props.onSelect(undefined);
            else props.onSelect(item);
            props.onClose();
          }}>{props.getLabel(item)}</Button>
        )}
      </Stack>
    }
  </Popover>
}

export function JSONInputField(props: {
  label?: string,
  variant?: TextFieldVariants,
  value: object | null,
  onChange: (val: object | null) => void,
}): React.ReactElement {
  // return <JsonView
  //   src={structuredClone(props.value)}
  //   enableClipboard={false}
  //   editable
  //   onEdit={c => {
  //     console.log(c)
  //     if (c.depth == 1 && 'newValue' in c && (c.parentType === null || c.parentType === undefined)) {
  //       props.onChange(c.newValue)
  //     }
  //   }}
  //   onAdd={c => {
  //     console.log(c)
  //     props.onChange(c.src);
  //   }}
  //   onDelete={c => {
  //     console.log(c)
  //     if (c.depth == 1) {
  //       props.onChange(null);
  //     }
  //   }}
  // />

  // to hold user input before it's valid for cases of non-text value
  const textValue = (val: any) => val === null || val === undefined
    ? '' : JSON.stringify(props.value, undefined, 2);
  const [tempInputValue, setTempInputValue] = useState(textValue(props.value));
  const [tempInputError, setTempInputError] = useState(false);

  useEffect(() => setTempInputValue(textValue(props.value)), [props.value]);

  return <TextField
    variant={props.variant}
    multiline
    maxRows={10}
    label={props.label ? `${props.label} (JSON)` : 'JSON'}
    value={tempInputValue}
    error={tempInputError}
    onChange={e => {
      setTempInputValue(e.target.value);
      try {
        if (!e.target.value) props.onChange(null);
        else props.onChange(JSON.parse(e.target.value));
        setTempInputError(false);
      } catch (error) {
        setTempInputError(true);
      }
    }}
  />
}

export const InlineCode = styled('span')({
  backgroundColor: '#f2f2f2',
  borderRadius: '3px',
  padding: '0.2em 0.4em',
  fontFamily: 'monospace',
  whiteSpace: 'pre-wrap',
});

export const TypeString = styled('span')({
  borderRadius: '3px',
  border: '1px solid #ccc',
  padding: '0.2em 0.4em',
  fontFamily: 'monospace',
});

export const ReactFlowContext = createContext(
  {
    onConnectionStartParams: null as OnConnectStartParams | null,
    setNodes: (updater: GraphNode[] | ((oldNodes: GraphNode[]) => GraphNode[])) => { },
  }
)

export function ListEditView<T>(props: {
  name?: string,
  values: T[],
  itemComponent: (v: T, onChange: (v: T) => void) => React.ReactElement,
  onChange: (vals: T[]) => void,
  default: T,
  additionalComponents?: React.ReactNode,
  disableAdd?: boolean,
}): React.ReactElement {
  return <Card elevation={3}>
    <Stack spacing={2} p={2}>
      {props.name
        ? <Typography variant='subtitle2'>{props.name}</Typography>
        : undefined
      }
      {props.additionalComponents}
      {(props.values).map((v, idx) => <Stack key={idx} direction='row' spacing={1} display='flex' alignItems='center'>
        <Box sx={{ flexGrow: 1, '& > *': { width: '100%' } }}>
          {props.itemComponent(v, v => {
            const newList = [...props.values];
            newList[idx] = v;
            props.onChange(newList);
          })
          }
        </Box>
        <IconButton onClick={() => {
          const newList = [...props.values];
          newList.splice(idx, 1);
          props.onChange(newList);
        }}><CloseIcon color='error' fontSize='small' /></IconButton>
      </Stack>
      )}
      {!props.disableAdd && <Button
        color='success'
        variant='outlined'
        sx={{ textTransform: 'none' }}
        onClick={() => props.onChange([...props.values, props.default])}
      ><AddRoundedIcon /> New Item</Button>
      }
    </Stack>
  </Card>
}

//these two path needs to match for the navigation to work
export function getEditorPath(mode: EditorMode, flowId?: string): string {
  return flowId ? `/editor/${mode}/${flowId}` : `/editor/${mode}/`;
}

export function getEditorRoutePath(): string {
  return `/editor/:mode(app|recipe)/:flowId?`;
}
