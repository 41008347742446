import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Stack, Dialog, Button, LinearProgress } from '@mui/material';
import { UploaderV2 } from './uploaderV2';
import { useFiles } from '../../hooks/AssetState';
import { useClientStore } from '../../hooks/ClientState';


export default function FileSelect(props: {
  fileId?: string,
  onChange: (fileId: string) => void,
  // only difference of this vs onChange is that fileName is provided
  onSubmit?: (fileName: string, fileId: string) => void,
  label?: string,
}): React.ReactElement {
  const label = props.label || "Select a file";
  const flowId = useClientStore(state => state.flowId);
  // TODO get siteId when uploading from editor?
  const { loading, files, refetch } = useFiles(undefined, flowId);
  const [dialogOpen, setDialogOpen] = useState(false);

  if (loading) {
    return <LinearProgress />;
  }

  return <Stack spacing={1} width='100%' p={2}>
    <FormControl>
      {label && <InputLabel id={label}>{label}</InputLabel>}
      <Select
        label={label}
        labelId={label}
        value={props.fileId || ''}
        onChange={e => {
          if (e.target.value) {
            const fileId = e.target.value as string;
            const fileName = files.find(f => f.id === fileId)?.fileName;
            props.onChange(e.target.value);
            props.onSubmit?.(fileName, fileId);
          }
          else {
            setDialogOpen(true);
          }
        }}
      >
        <MenuItem value=''>
          <Button variant='contained' sx={{ textTransform: 'none' }} onClick={() => setDialogOpen(true)}>Upload new file</Button>
        </MenuItem>
        {files.map(d => <MenuItem key={d.id} value={d.id}>{d.fileName} ({d.id})</MenuItem>)}
      </Select >
    </FormControl>
    <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth='md'>
      <UploaderV2
        maxFiles={1}  // TODO should be specified by the caller
        onComplete={files => {
          refetch();
          const [fileName, fileId] = Object.entries(files)[0];
          props.onChange(fileId);
          props.onSubmit?.(fileName, fileId);
          setDialogOpen(false);
        }}
        // TODO get siteId when uploading from editor?
        flowId={flowId}
      />
    </Dialog>
  </Stack>
}
