import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { DynamicValue } from '../../types/DynamicValueTypes';
import { FlowNodeName } from '../../features/Pixie/Editor/FlowNodeName';
import { useEditorStore } from '../../hooks/EditorState';
import { parseDistance } from '../../types/DynamicValueTypes';
import JsonView from 'react18-json-view';


function RelativeFlowNodeName(props: {
  distance: number;
}): React.ReactElement {
  const relatedNodeIds = useEditorStore(state => state.actions.graph.getRelatedNodeIds(props.distance));

  return <Stack direction='row' spacing={1} display='inline-flex' alignItems='baseline' flexWrap='wrap' divider={<p>|</p>}>
    {relatedNodeIds.map(nodeId => <FlowNodeName key={nodeId} nodeId={nodeId} variant='description' />)}
  </Stack>
}

export function DynamicValueDisplay(props: {
  dynamicValue: DynamicValue,
}): React.ReactElement {
  const distance = parseDistance(props.dynamicValue.reference);

  return <Stack
    direction='row'
    display='inline-flex'
    alignItems='baseline'
    flexWrap='wrap'
    sx={{
      borderRadius: '3px',
      border: '1px solid #ccc',
      padding: '0.2em 0.4em',
      fontFamily: 'monospace',
    }}
  >
    {distance !== null
      ? <RelativeFlowNodeName distance={distance} />
      : <FlowNodeName nodeId={props.dynamicValue.reference} variant='description' />
    }
    {props.dynamicValue.access_path.map(
      (seg, idx) => seg.type != 'typeSelect' && <Box key={idx} sx={{
        cursor: 'default',
        userSelect: 'none',
        // '&:hover': {
        //   textDecorationLine: 'underline',
        //   textDecorationColor: theme.palette.secondary.main,
        //   textDecorationThickness: 3,
        //   textUnderlineOffset: '4px',
        // }
      }}>
        <Typography p='1px' key={idx}>
          <b>{seg.type == 'property' ? `.${seg.value}` : `[${seg.value}]`}</b>
        </Typography>
      </Box>
    )}

    {Boolean(props.dynamicValue.version_delta) && <Typography variant='subtitle2' pl={1}>
      {`(${props.dynamicValue.version_delta} version${props.dynamicValue.version_delta == 1 ? '' : 's'} back)`}
    </Typography>}
    {![null, undefined].includes(props.dynamicValue.default) && <Typography variant='subtitle2' pl={1}>
      Default when missing: <JsonView
        src={props.dynamicValue.default}
        collapseStringsAfterLength={10}
        collapsed={1}
      />
    </Typography>}
  </Stack>;
}
